/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
.butnLogin {
  background: #004fff 0% 0% no-repeat padding-box;
  border-radius: 50px;
  width: 120px;
  height: 35px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: Bold;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-right: -30px;
}
.butnLogin:hover {
  border: 1px solid #004fff;
  color: #004fff;
  background: #fff 0% 0% no-repeat padding-box;
}
#home p {
  text-align: justify;
}
#home html,
#home body {
  font-family: Roboto, Helvetica, sans-serif;
}
#home li:hover,
#home a:hover {
  border-color: none;
}
#home .container {
  width: 100%;
  margin: 0 auto;
}
#home .ant-layout-header,
#home .ant-layout {
  background: transparent;
  padding: 0;
}
@media (min-width: 480px) {
  #home .container {
    max-width: 480px;
  }
}
@media (min-width: 576px) {
  #home .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  #home .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  #home .container {
    max-width: 992px;
  }
}
@media (min-width: 1200px) {
  #home .container {
    max-width: 1200px;
  }
}
#home .backgroundMenuMobile img {
  width: 100% !important;
  cursor: pointer;
  margin-top: 5px;
}
#home #topProMqn {
  background: #41d7aa;
  height: 50px;
}
#home #topProMqn .headerTopProMqnMain {
  position: relative;
  top: -5px;
  padding: 0px 24px;
}
#home #topProMqn .itemAdress {
  float: left;
  margin-right: 0px;
  width: 620px;
}
#home #topProMqn .itemAdress p {
  font-size: 12px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
#home #topProMqn .itemAdress img {
  width: 24px;
  float: left;
  margin: 18px 10px 0px;
}
#home #topProMqn .IconLang {
  width: 30%;
  height: 25px;
  object-fit: cover;
  margin-right: 10px;
}
#home #meuMainPC {
  height: 769px;
  background: transparent url('/static/img/masthead-watter-bg.png') 0% 0% no-repeat padding-box;
  background-size: cover;
}
#home #meuMainPC .menuMainMqn a {
  color: black;
  margin-right: -24px;
}
#home #meuMainPC .header {
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  margin-top: 50px;
}
#home #meuMainPC .logo img {
  width: 60%;
  margin-top: 10px;
  cursor: pointer;
}
#home #meuMainPC .ant-menu {
  background: transparent !important;
}
#home #meuMainPC .ant-menu-item-selected {
  color: black;
  border: none;
}
#home #meuMainPC .ant-menu-horizontal {
  border: none;
}
#home #meuMainPC .ant-menu-horizontal > .ant-menu-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #2C2D2D;
  text-transform: uppercase;
  opacity: 1;
  font-weight: Bold;
  margin-top: 20px;
}
#home #meuMainPC .ant-menu-horizontal > .ant-menu-item,
#home #meuMainPC .ant-menu-horizontal > .ant-menu-submenu {
  color: #004fff;
  border: none !important;
}
#home #bannerHeaderHome {
  margin-top: 50px;
}
#home #bannerHeaderHome .contentTextInfoHeader h3 {
  color: #41d7aa;
  margin-bottom: 0px;
  margin-top: 35px;
  text-transform: uppercase;
}
#home #bannerHeaderHome .contentTextInfoHeader {
  margin-top: 120px;
}
#home #bannerHeaderHome .contentTextInfoHeader h1 {
  text-align: left;
  font-size: 32px;
  line-height: 50px;
  font-weight: Bold;
  color: #174eff;
}
#home #bannerHeaderHome .contentTextInfoHeader h2 {
  text-align: left;
  font-size: 22px;
  color: #2C2D2D;
  margin-bottom: 30px;
  font-weight: normal;
  text-align: justify;
}
#home #bannerHeaderHome .imgHeader img {
  width: 100%;
  margin-top: 50px;
}
#home #bannerHeaderHome .resAccountMqn {
  background: #004fff 0% 0% no-repeat padding-box;
  border-radius: 50px;
  width: 203px;
  height: 60px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-right: 30px;
  border: none;
}
#home #bannerHeaderHome .resAccountMqn:hover {
  background: #fff 0% 0% no-repeat padding-box;
  border: 3px solid #004fff;
  color: #004fff;
}
#home #bannerHeaderHome .contactSale {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 3px solid #004fff;
  border-radius: 50px;
  text-align: center;
  width: 203px;
  height: 60px;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #004fff;
  text-transform: uppercase;
}
#home #bannerHeaderHome .contactSale:hover {
  background: #004fff 0% 0% no-repeat padding-box;
  border: 3px solid #004fff;
  color: #fff;
}
#home #bannerServiePack {
  margin-top: 100px;
}
#home #bannerServiePack .titleBanner h1 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #174eff;
  text-transform: uppercase;
}
#home #bannerServiePack .titleBanner img {
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  width: 8%;
}
#home #bannerServiePack .titleBanner p {
  text-align: center;
  font-size: 20px;
  width: 600px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
#home #bannerServiePack .MainServieMqn {
  margin-top: 100px;
}
#home #bannerServiePack .itemServieMqn {
  float: left;
  width: 30%;
  margin-right: 3%;
  margin-bottom: 70px;
}
#home #bannerServiePack .itemServieMqn img {
  float: left;
  margin: 0px 20px 0px 0px;
  width: 82px;
  height: 76px;
}
#home #bannerServiePack .itemServieMqn h1 {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #2C2D2D;
  text-transform: uppercase;
}
#home #bannerServiePack .itemServieMqn p {
  text-align: left;
  font-size: 14px;
  line-height: 25px;
  color: #2C2D2D;
}
#home #bannerServiePack .itemContentData {
  margin-bottom: 50px;
}
#home #bannerServiePack .itemContentData img {
  width: 90%;
}
#home #bannerServiePack .desctionContent {
  margin-top: 50px;
}
#home #bannerServiePack .desctionContent h1 {
  color: #174eff;
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
}
#home #bannerServiePack .desctionContent p {
  font-size: 16px;
  line-height: 25px;
  color: #707070;
}
#home #bannerServiePack .coloBlue {
  color: #18d7aa !important;
}
#home #bannerItemShowInfoMqn .titleFeatureIndex {
  margin-top: 50px;
  margin-bottom: 50px;
}
#home #bannerItemShowInfoMqn .titleFeatureIndex h3 {
  font-size: 22px;
  color: #18d7aa;
  margin-bottom: -10px;
}
#home #bannerItemShowInfoMqn .titleFeatureIndex h1 {
  font-size: 30px;
  color: #004fff;
  font-weight: bold;
  margin-bottom: -10px;
}
#home #bannerItemShowInfoMqn .titleFeatureIndex p {
  font-weight: 300;
  font-size: 24px;
}
#home #bannerItemShowInfoMqn .btnShowMore {
  background: #004fff 0% 0% no-repeat padding-box;
  border-radius: 50px;
  width: auto;
  height: 45px;
  border: none;
  text-align: center;
  font-size: 16px;
  font-weight: Bold;
  color: #FFFFFF;
  text-transform: uppercase;
}
#home #bannerItemShowInfoMqn .btnShowMore:hover {
  background: #fff 0% 0% no-repeat padding-box;
  color: #004fff;
  border: 2px solid #004fff;
}
#home #bannerItemShowInfoMqn .itemArrow img {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}
#home #bannerItemShowInfoMqn .itemImgBannerShow img {
  width: 100%;
}
#home #bannerItemShowInfoMqn .contentImgBannerShow h1 {
  text-align: left;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #174eff;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-bottom: 10px;
}
#home #bannerItemShowInfoMqn .contentImgBannerShow img {
  width: 8%;
  margin-bottom: 15px;
}
#home #bannerItemShowInfoMqn .contentImgBannerShow p {
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0px;
  color: #2C2D2D;
}
#home #contactMainProMqn {
  background: transparent url(/static/img/backgroundContact.PNG) 0% 0% no-repeat padding-box;
  height: 100%;
  background-attachment: fixed;
  background-size: 100% 100%;
}
#home #contactMainProMqn .titleContactMain {
  margin: 0 auto;
}
#home #contactMainProMqn .titleContactMain h1 {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  color: #FFFFFF;
  padding: 20px 0px;
  width: auto;
  margin: 0 auto;
  margin-bottom: 20px;
  text-transform: uppercase;
  margin-top: 40px;
}
#home #contactMainProMqn .titleContactMain p {
  text-align: center;
  font-size: 16px;
  color: #FFFFFF;
}
#home #contactMainProMqn .itemFormContactMain {
  display: block;
  margin: 0 auto;
}
#home #contactMainProMqn .buttonContact {
  background: white 0% 0% no-repeat padding-box;
  border-radius: 50px;
  width: 182px;
  height: 42px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #004fff;
  text-transform: uppercase;
  display: block;
  margin: 0 auto;
  border: none;
  margin-top: 15px;
}
#home #footerMainProMqn {
  padding: 50px 0px;
  background: #2c2d2d !important;
}
#home #footerMainProMqn h1 {
  color: white;
  margin-left: 22px;
}
#home #footerMainProMqn .menuMainMqn {
  margin-left: 50px;
}
#home #footerMainProMqn .menuMainMqn a {
  color: white;
}
#home #footerMainProMqn .itemContacFooter {
  margin-left: 20px;
  margin-bottom: 30px;
}
#home #footerMainProMqn .itemContacFooter p {
  color: white;
  font-size: 14px;
}
#home #footerMainProMqn .itemContacFooter img {
  width: 24px;
  float: left;
  margin: 0px 15px 0px 0px;
}
#home #footerMainProMqn .logo img {
  width: 70%;
}
#home #footerMainProMqn .menuMainMqn ul {
  list-style-type: none;
  margin-left: -18px;
}
#home #footerMainProMqn .menuMainMqn ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
#home #footerMainProMqn .logo p {
  color: white;
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}
#home #footerMainProMqn .ant-menu {
  background: transparent !important;
}
#home #footerMainProMqn .ant-menu-item-selected {
  color: black;
  border: none;
}
#home #footerMainProMqn .ant-menu-horizontal {
  border: none;
}
#home #footerMainProMqn .ant-menu-horizontal > .ant-menu-item {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2C2D2D;
  text-transform: uppercase;
  opacity: 1;
  font-weight: Bold;
}
#home #footerMainProMqn .ant-menu-horizontal > .ant-menu-item,
#home #footerMainProMqn .ant-menu-horizontal > .ant-menu-submenu {
  color: white;
  border: none !important;
}
#home #footerMainProMqn .headerCopyRight {
  border-top: 3px dotted #18d7aa;
  padding: 55px 0px;
}
#home #footerMainProMqn .headerCopyRight p {
  color: white;
}
#home #footerMainProMqn .header2 {
  margin-bottom: 60px;
}
#home #footerMainProMqn .iconSocialFooter img {
  width: 45px;
}
#home #footerMainProMqn .iconSocialFooter {
  float: right;
}
#home #footerMainProMqn .iconSocialFooter img {
  margin-top: -10px;
}
#home #bannerCampaignMqnMain {
  margin-top: 100px;
}
#home #bannerCampaignMqnMain .headerContent {
  background: white;
  padding: 10px;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.45);
}
#home #bannerCampaignMqnMain .bodyContent {
  background: #f6f6f6;
  padding: 10px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.45);
}
#home #bannerCampaignMqnMain .footerContent {
  background: #00d1b2;
  padding: 10px;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.45);
}
#home #bannerCampaignMqnMain .footerContent img {
  width: 27px !important;
  height: 27px !important;
  margin: 0px 10px 0px 0px !important;
  float: left !important;
}
#home #bannerCampaignMqnMain .footerContent p {
  font-size: 18px;
}
#home #bannerCampaignMqnMain .imgbackground {
  position: relative;
  background-repeat: no-repeat !important;
  height: 500px;
  background-size: 100% !important;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
  width: 100%;
  border-radius: 30px;
}
#home #bannerCampaignMqnMain .imgbackground img {
  text-align: center;
  width: 100%;
  height: 410px;
  object-fit: cover;
}
#home #bannerCampaignMqnMain .bannerPostHeigh {
  height: 100% !important;
  width: 30% !important;
}
#home #bannerCampaignMqnMain .contentDesciptionMqn {
  position: absolute;
  top: 8px;
  left: 16px;
  color: white;
}
#home #bannerCampaignMqnMain .contetnInfoBannercampains {
  position: absolute;
  padding: 8px;
  border-radius: 10px;
  width: 350px;
  float: right;
}
#home #bannerCampaignMqnMain .bodyContent {
  color: black;
}
#home #bannerCampaignMqnMain .contetnInfoBannercampains h3 {
  font-size: 18px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
#home #bannerCampaignMqnMain .contetnInfoBannercampains p,
#home #bannerCampaignMqnMain .contetnInfoBannercampains h4 {
  margin-bottom: 5px;
}
#home #bannerCampaignMqnMain .contentDesciptionMqn h1 {
  text-align: left;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.64px;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-bottom: 10px;
}
#home #bannerCampaignMqnMain .contentDesciptionMqn h2 {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0.64px;
  color: #FFFFFF;
  font-weight: normal;
}
#home #bannerCampaignMqnMain .contentDesciptionMqn h6 {
  color: white;
  font-size: 20px;
}
#home #bannerCampaignMqnMain .itemCampaignMain {
  margin-bottom: 120px;
}
#home #bannerCampaignMqnMain .itemPoster img {
  width: 40%;
  height: auto;
  margin-right: 15px;
  bottom: -50px;
  right: 0;
  margin-top: -80px;
  position: absolute;
  border: 12px solid #2c2d2d;
}
#home #bannerCampaignMqnMain .td150 {
  margin-top: -150px !important;
}
#home #bannerIndoorMainMqn {
  margin-top: 100px;
}
#home #bannerIndoorMainMqn .itemIndoor {
  margin-bottom: 30px;
  cursor: pointer;
}
#home #bannerIndoorMainMqn .titleIndoor h1 {
  text-align: center;
  font-size: 32px;
  font-weight: Bold;
  color: #1e5ee5;
  margin-bottom: 5px;
  text-transform: uppercase;
}
#home #bannerIndoorMainMqn .titleIndoor p {
  text-align: center;
  font-size: 20px;
  width: 600px;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}
#home #bannerIndoorMainMqn .titleIndoor img {
  display: block;
  width: 8%;
  margin: 0 auto;
  margin-bottom: 50px;
}
#home #bannerIndoorMainMqn .ImgInodorMqn img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
#home #bannerIndoorMainMqn .itemIconContentIndoor h1 {
  text-align: left;
  font-size: 16px;
  margin-top: 0px;
  letter-spacing: 0px;
  color: #2C2D2D;
  text-transform: uppercase;
}
#home #bannerIndoorMainMqn .itemIconContentIndoor {
  margin-top: 20px;
}
#home #bannerIndoorMainMqn .itemIconContentIndoor img {
  float: left;
  margin: 3px 20px 0px 0px;
}
#home #bannerIndoorMainMqn .itemIconContentIndoor p {
  font-size: 18px;
  color: #2C2D2D;
}
#home .whitebg {
  background: white;
  height: 85px;
  margin-top: -50px !important;
}
#home .whitebg .logo img {
  width: 45% !important;
  margin-top: 10px !important;
}
#home .backgroundMenuMobile {
  display: none;
}
#home .lineTop {
  border-top: 3px dotted #18d7aa;
  content: "";
  display: inline-block;
  height: 0.5em;
  margin-right: 10px;
  width: 5em;
}
#home #detailLocation .imgAvataLocation img {
  width: 100%;
  margin-bottom: 20px;
}
#home #detailLocation .infoMainContent:not(:last-child) {
  margin-bottom: 1.5rem;
}
#home #detailLocation .infoMainContent h6 {
  color: #949ea5;
  font-size: 20px;
}
#home #detailLocation .infoMainContent h1 {
  color: #004fff;
  font-size: 2.75rem;
  line-height: 60px;
}
#home #detailLocation .infoMainContent h2 {
  color: #949ea5;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 20px;
}
#home #detailLocation .infoMainContent p {
  color: #476074;
}
#home #detailLocation .infoMainContent h3 {
  margin-top: 1.1428em;
  color: #29334d;
  margin-bottom: 0.5714em;
  font-size: 1.75em;
  font-weight: normal;
}
#home #detailLocation .contentItemServie {
  margin-top: 20px;
}
#home #detailLocation .itemServieDetail img {
  height: 64px;
  width: 64px;
  margin-top: 15px;
}
#home #detailLocation .itemServieDetail h1 {
  color: #363636;
  font-size: 18px;
  margin-bottom: 0px;
}
#home #detailLocation .imgSpecSrceen img {
  width: 80%;
}
#home #detailLocation .imgSpecSrceen video {
  width: 100%;
}
#home #detailLocation .itemInfoContentSpec h1 {
  color: #476074;
  font-size: 19px;
  margin-bottom: -10px;
}
#home #detailLocation .itemInfoContentSpec {
  margin-top: 20px;
}
#home #detailLocation .mainInfoRelative img {
  position: relative;
  height: 100%;
}
#home #detailLocation .infoDetailMqn {
  position: absolute;
  bottom: 50%;
}
#home #detailLocation .customCarosel {
  margin-top: 50px;
  margin-bottom: 50px;
}
#home .ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
  width: 80%;
  height: 450px;
}
#home .ant-carousel .slick-slide h3 {
  color: #fff;
}
#home .ant-carousel .slick-dots li.slick-active button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: #427df8;
}
#home .ant-carousel .slick-dots li button {
  width: 15px;
  height: 15px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  background: white;
  background: #427df8;
}
#home .customCarosel img {
  width: 70%;
  height: 450px;
  margin: 0 auto;
  display: block;
  border-radius: 20px;
}
#home .scrollUpButton img {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 4%;
  cursor: pointer;
}
#home #logoPartner {
  margin-bottom: 100px;
  margin-top: 160px;
}
#home #logoPartner img {
  width: auto;
  top: 50%;
  max-width: 65%;
  max-height: 60%;
  display: block;
  margin: 0 auto;
  margin-bottom: 70px;
}
#home #logoPartner .ItemInfoLogoPartnerMqn {
  border-left: 3px dotted #18d7aa;
  border-right: 3px dotted #18d7aa;
  border-top: 3px dotted #18d7aa;
  content: "";
  display: block;
  height: 100%;
  margin-top: 1.25em;
  width: 100%;
  z-index: 0;
  padding: 0px 20px;
}
#home #logoPartner .ItemInfoLogoPartnerMqn h1 {
  color: #18d7aa !important;
  position: relative;
  padding: 1em;
  text-align: center;
  margin: 0 auto;
  margin-top: -40px;
  width: 200px;
  background: white;
}
#home #logoPartner .ItemInfoLogoPartnerMqn h2 {
  color: #004fff !important;
  text-align: center;
  font-size: 3.5rem;
  line-height: 65px;
  width: 700px;
  margin: 0 auto;
  margin-bottom: 50px;
}
#home #logoPartner .ItemInfoLogoPartnerMqn img {
  filter: grayscale(100%);
}
#home #backgroundVideoMqnMain {
  padding: 32px;
}
#home #backgroundVideoMqnMain .videoMarketing {
  border: 3px dotted white;
  border-radius: 2em;
  max-width: 925px;
  padding: 2em;
}
#home #backgroundVideoMqnMain .videoMainDetail img {
  margin: 0 auto;
  display: block;
  width: 25%;
}
#home #backgroundVideoMqnMain .videoMarketing video {
  width: 100%;
  height: auto;
}
#home #backgroundVideoMqnMain .titleVideo h1 {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #174eff;
  font-size: 32px;
}
#home #startCampsiteMqn {
  background: url('/static/img/Ho_Chi_Minh_City_Skyline.png') no-repeat;
  background-size: cover;
  padding: 50px;
  height: 400px;
  background-attachment: fixed;
  background-position: 50% 72%;
}
#home #startCampsiteMqn .infoStartCampsMqn h1 {
  color: rgba(255, 255, 255, 0.9);
  font-weight: normal;
  font-size: 26px;
  margin-top: 25px;
  margin-bottom: 0px;
}
#home #startCampsiteMqn .infoStartCampsMqn h2 {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.9);
  font-size: 3.5rem;
}
#home #startCampsiteMqn .infoStartCampsMqn button {
  color: rgba(255, 255, 255, 0.9);
  padding: 0px 20px;
  font-weight: bold;
  border: 3px solid;
  background: transparent;
  border-radius: 20px;
  height: 38px;
  font-size: 18px;
  box-sizing: border-box;
  display: inline-block;
  line-height: 1.9em;
  text-align: center;
  position: absolute;
  top: 60%;
}
#home #startCampsiteMqn .infoStartCampsMqn button:hover {
  color: #293d4d;
  border: 3px solid rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.9);
}
#home #speccificationMqnMain {
  background-color: #dcecf8;
  padding: 50px;
}
#home #speccificationMqnMain .tableMainWhiteSpec {
  background: white;
  padding: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 15px grey;
}
#home #speccificationMqnMain .table thead td,
#home #speccificationMqnMain .table thead th {
  border-width: 0 0 2px;
  color: #363636;
}
#home #speccificationMqnMain .table th {
  color: #363636;
  text-align: left;
}
#home #speccificationMqnMain .table td,
#home #speccificationMqnMain .table th {
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}
#home #speccificationMqnMain .table {
  background-color: #fff;
  color: #363636;
  margin-bottom: 1.5rem;
}
#home #speccificationMqnMain .is-vcentered {
  align-items: center;
  display: flex;
}
#home #speccificationMqnMain .icon.is-large {
  height: 3rem;
  width: 3rem;
}
#home #speccificationMqnMain .is-grey {
  color: #949ea5 !important;
}
#home #speccificationMqnMain .table.is-fullwidth {
  width: 100%;
}
#home #speccificationMqnMain .titleSpec h3 {
  color: #41d7aa;
  margin-bottom: 0px;
  margin-top: 35px;
  text-transform: uppercase;
}
#home #speccificationMqnMain .titleSpec h1 {
  text-align: left;
  font-size: 48px;
  line-height: 70px;
  font-weight: Bold;
  color: #174eff;
  margin-bottom: 0px;
  text-transform: uppercase;
}
#home #speccificationMqnMain .titleSpec h2 {
  text-align: left;
  font-size: 22px;
  color: #2C2D2D;
  margin-bottom: 30px;
  font-weight: normal;
}
#home .borderTopTbody {
  border-top: 3px dotted #55d7aa;
}
#home #bannerBLogMain {
  background: #dcecf8;
  margin-top: 50px;
  padding: 50px;
}
#home #bannerBLogMain .itemBlogMqn {
  background: white;
  padding: 24px;
  border-radius: 30px;
  margin-top: 50px;
}
#home #bannerBLogMain .itemBlogMqn img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  margin-bottom: 20px;
}
#home #bannerBLogMain .itemBlogMqn h1 {
  color: #004fff !important;
  font-size: 28px;
}
#home #bannerBLogMain .itemBlogMqn h3 {
  float: left;
  margin: -2px 20px 0px 0px;
  font-size: 16px;
  color: #949ea5 !important;
}
#home #bannerBLogMain .itemBlogMqn h4 {
  margin-top: 0px;
}
#home #bannerBLogMain .itemBlogMqn button {
  border: 2px solid #004fff;
  border-radius: 45px;
  color: #004fff;
  font-weight: bold;
  text-transform: uppercase;
}
#home #bannerHoverChange {
  background: #dcecf8;
  padding: 50px;
}
#home #bannerHoverChange .titleFeatureIndex {
  margin-top: 50px;
  margin-bottom: 50px;
}
#home #bannerHoverChange .titleFeatureIndex h3 {
  font-size: 22px;
  color: #18d7aa;
  margin-bottom: -10px;
}
#home #bannerHoverChange .titleFeatureIndex h1 {
  font-size: 60px;
  color: #004fff;
  font-weight: bold;
  line-height: 70px;
  margin-bottom: 5px;
  margin-top: 20px;
}
#home #bannerHoverChange .titleFeatureIndex p {
  font-weight: 300;
  font-size: 24px;
}
#home #bannerHoverChange .itemBackgroundLaptop {
  background: url('/static/img/computer.png') no-repeat;
  background-size: 100% 100%;
  height: 550px;
}
#home #bannerHoverChange .itemBackgroundLaptop .img {
  position: relative;
}
#home #bannerHoverChange .itemBackgroundLaptop img {
  width: 100%;
  opacity: 0;
  transform: translate3d(0, 120px, 0);
  transition: 0.75s;
  position: absolute;
}
#home #bannerHoverChange .itemBackgroundLaptop img.selected {
  opacity: 1;
  transform: translate3d(0, 0px, 0);
}
#home #bannerHoverChange .itemNumberMain p {
  border: 3px solid transparent;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}
#home #bannerHoverChange .itemNumberMain {
  width: 70px;
  height: 70px;
  padding: 8px 0px;
  border-radius: 50%;
  color: #004fff;
  opacity: 0.3;
  transition: 0.75s;
  border: 2px solid #18d7aa;
}
#home #bannerHoverChange .itemNumberMain.selected {
  border: none;
  color: #fff;
  background: linear-gradient(45deg, #bddff7 28%, #18d7aa 96%);
  opacity: 1;
}
#home #bannerHoverChange .infoMainDetailMqn {
  opacity: 0.3;
  transition: 0.75s;
}
#home #bannerHoverChange .infoMainDetailMqn.selected {
  opacity: 1;
}
#home #bannerHoverChange .infoMainDetailMqn h1 {
  font-size: 2rem;
  color: #004fff;
}
#home #bannerHoverChange .infoMainDetailMqn button {
  color: #00d1b2;
  background: transparent;
  border: none;
  font-weight: bold;
}
#home #bannerHoverChange .infoMainDetailMqn p {
  font-weight: 300;
  font-size: 18px;
}
/* .ant-btn-primary{
    background: @primary-color-gradient;
    border: none;
} */
@media (max-width: 1199px) {
  #home #contactMainProMqn {
    height: 100%;
    background-size: cover;
    padding: 50px;
  }
  #home #footerMainProMqn {
    padding: 50px;
  }
  #home #footerMainProMqn .logo img {
    width: 40%;
  }
  #home #footerMainProMqn p {
    text-align: left;
    font-size: 18px;
  }
  #home #footerMainProMqn .ant-menu-horizontal > .ant-menu-item {
    font-size: 22px;
  }
  #home #backgroundVideoMqnMain {
    padding: 50px;
  }
  #home #bannerServiePack .titleBanner p {
    width: auto;
  }
  #home #bannerServiePack .itemServieMqn {
    width: 92%;
    margin: 0;
    margin-bottom: 30px;
  }
  #home #bannerServiePack .itemServieMqn h1 {
    font-size: 24px;
  }
  #home #bannerServiePack .itemServieMqn p {
    font-size: 26px;
    line-height: 35px;
  }
  #home #bannerServiePack .itemServieMqn img {
    height: 97px;
    width: 140px;
  }
  #home #bannerServiePack .MainServieMqn {
    margin-top: 50px;
  }
  #home #bannerItemShowInfoMqn .contentImgBannerShow h1 {
    font-size: 30px;
  }
  #home #bannerItemShowInfoMqn .contentImgBannerShow p {
    font-size: 22px;
    line-height: 30px;
  }
  #home #bannerHeaderHome .resAccountMqn,
  #home #bannerHeaderHome .contactSale {
    font-size: 26px;
    padding: 0px 16px;
  }
  #home #bannerHeaderHome .imgHeader img {
    width: 95%;
  }
  #home .googleMap iframe {
    margin-bottom: 0px !important;
  }
  #home #bannerHeaderHome .contactSale,
  #home #bannerHeaderHome .resAccountMqn {
    font-size: 20px;
  }
  #home #bannerIndoorMainMqn .itemIndoor {
    padding: 0px 16px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor h1 {
    font-size: 14px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor p {
    font-size: 14px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor img {
    margin: 3px 5px 0px 0px;
  }
  #home #bannerHeaderHome {
    margin-top: 50px;
  }
  #home #bannerHeaderHome .contentTextInfoHeader {
    margin-top: 88px;
  }
  #home #bannerCampaignMqnMain .imgbackground {
    background-size: cover !important;
    background-attachment: inherit !important;
  }
  #home #bannerCampaignMqnMain .contetnInfoBannercampains {
    width: 70%;
  }
  #home #meuMainPC .logo img {
    width: 80% !important;
  }
  #home #startCampsiteMqn .infoStartCampsMqn h2 {
    font-size: 2.5rem;
  }
  #home #bannerIndoorMainMqn .titleIndoor p {
    width: auto;
  }
  #home #topProMqn .IconLang {
    width: 35%;
    height: 20px;
  }
  #home #detailLocation .infoMainContent {
    padding: 0px 24px;
  }
  #home #detailLocation .infoMainContent p {
    color: #476074;
    width: auto;
    font-size: 15px;
  }
  #home #bannerHoverChange {
    height: auto;
    margin-top: 100px;
  }
  #home #bannerHoverChange .itemBackgroundLaptop img {
    width: 100%;
    object-fit: cover;
    height: auto;
  }
  #home #bannerHoverChange .itemBackgroundLaptop {
    height: auto;
  }
  #home #bannerHoverChange .infoMainDetailMqn h1 {
    font-size: 30px;
    margin-bottom: 0px;
  }
  #home #bannerHoverChange .itemNumberMain {
    width: 50px;
    height: 50px;
    padding: 0px;
  }
  #home #topProMqn .itemAdress {
    width: auto;
  }
}
@media (max-width: 991px) {
  #home html {
    overflow-X: hidden !important;
  }
  #home .contentTextInfoHeader {
    padding: 0px 16px;
  }
  #home #bannerServiePack {
    padding: 0px 16px;
  }
  #home #bannerServiePack .itemServieMqn {
    width: 100%;
    margin: 0px;
  }
  #home #bannerServiePack .itemServieMqn h1 {
    font-size: 24px;
  }
  #home #bannerServiePack .itemServieMqn p {
    line-height: 42px;
    font-size: 28px;
  }
  #home #bannerItemShowInfoMqn {
    padding: 0px 16px;
  }
  #home #bannerItemShowInfoMqn .itemArrow img {
    margin-bottom: 10px;
    margin-top: 30px;
  }
  #home #contactMainProMqn .titleContactMain h1 {
    width: auto;
  }
  #home #contactMainProMqn .itemFormContactMain {
    padding: 0px 16px;
  }
  #home #bannerItemShowInfoMqn .contentImgBannerShow p {
    font-size: 24px;
    line-height: 36px;
  }
  #home #footerMainProMqn .ant-menu-horizontal > .ant-menu-item {
    font-size: 14px;
  }
  #home #bannerItemShowInfoMqn .itemArrow img {
    width: 100%;
  }
  #home .backgroundMenuMobile {
    display: block !important;
    background: white;
    padding: 0px 50px;
    position: fixed;
    top: 0;
    z-index: 99999;
    width: 100%;
  }
  #home .header {
    display: none;
  }
  #home #meuMainPC .logo img {
    margin-top: 0px;
    width: 28% !important;
  }
  #home .ant-menu-root.ant-menu-vertical {
    margin-top: 70px;
  }
  #home #bannerCampaignMqnMain .contetnInfoBannercampains {
    width: 70%;
  }
  #home #detailLocation {
    padding: 0px 24px;
  }
  #home #detailLocation .imgSpecSrceen video {
    margin-bottom: 70px;
  }
  #home #detailLocation .itemServieDetail p,
  #home #detailLocation .itemServieDetail h1 {
    margin-left: 20px;
  }
  #home #bannerHeaderHome {
    margin-top: 0px;
  }
  #home #bannerHeaderHome .contentTextInfoHeader {
    margin-top: 20px;
  }
  #home #topProMqn {
    display: none;
  }
  #home .itemContacFooter,
  #home h1 {
    margin-left: 0px;
  }
  #home #footerMainProMqn .itemContacFooter,
  #home #footerMainProMqn .menuMainMqn ul,
  #home #footerMainProMqn h1 {
    margin-left: 0px;
  }
  #home #footerMainProMqn .menuMainMqn ul {
    margin-left: -32px;
  }
  #home #footerMainProMqn .menuMainMqn {
    margin-left: 0px;
  }
  #home #footerMainProMqn .menuMainMqn,
  #home #footerMainProMqn .itemContact {
    margin-top: 50px;
  }
  #home #bannerHoverChange .titleFeatureIndex h1 {
    font-size: 50px;
  }
  #home #bannerHoverChange .itemBackgroundLaptop {
    margin-bottom: 72%;
  }
  .IconLang {
    width: 50px;
    margin-left: 15px;
  }
}
@media (max-width: 575px) {
  #home #bannerHeaderHome .contentTextInfoHeader h1 {
    margin-bottom: 0px;
    font-size: 24px;
    line-height: 40px;
    margin-top: 0px;
  }
  #home #bannerHeaderHome .contentTextInfoHeader h2 {
    text-align: unset;
  }
  #home #bannerHeaderHome .contactSale,
  #home #bannerHeaderHome .resAccountMqn {
    font-size: 14px;
    width: auto;
    height: 48px;
  }
  #home #bannerServiePack .titleBanner h1 {
    font-size: 22px;
  }
  #home #bannerServiePack .itemServieMqn {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
  #home #bannerServiePack .MainServieMqn {
    margin-top: 20px;
  }
  #home #bannerServiePack .itemServieMqn h1 {
    font-size: 16px;
  }
  #home #bannerServiePack .itemServieMqn p {
    line-height: 22px;
    font-size: 14px;
  }
  #home #bannerServiePack .itemServieMqn img {
    height: 75px;
  }
  #home #bannerItemShowInfoMqn .contentImgBannerShow p {
    font-size: 18px;
    line-height: 32px;
  }
  #home #bannerItemShowInfoMqn .contentImgBannerShow h1 {
    font-size: 24px;
  }
  #home #bannerItemShowInfoMqn .titleFeatureIndex h1 {
    font-size: 32px;
    color: #004fff;
    font-weight: bold;
    line-height: 35px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  #home #bannerItemShowInfoMqn .btnShowMore {
    font-size: 12px;
    margin-bottom: 20px;
    width: 100%;
    white-space: normal;
    word-wrap: break-word;
    padding: 6px 20px;
  }
  #home #contactMainProMqn {
    height: auto;
  }
  #home #contactMainProMqn .titleContactMain p {
    padding: 0px 16px;
  }
  #home #contactMainProMqn .titleContactMain h1 {
    font-size: 20px !important;
  }
  #home #footerMainProMqn {
    padding: 16px;
  }
  #home #footerMainProMqn .ant-menu-horizontal > .ant-menu-item {
    font-size: 10px;
    float: right;
  }
  #home #footerMainProMqn .logo img {
    width: 90%;
  }
  #home #footerMainProMqn p {
    text-align: left;
    font-size: 12px;
  }
  #home #footerMainProMqn .iconSocialFooter img {
    margin-top: 0px;
    width: 32px;
  }
  #home .scrollUpButton img {
    width: 8%;
  }
  #home #bannerCampaignMqnMain .contentDesciptionMqn h1 {
    font-size: 24px;
  }
  #home #bannerCampaignMqnMain .imgbackground {
    padding: 100% 0px;
  }
  #home #bannerCampaignMqnMain .contentDesciptionMqn {
    padding: 20px;
    position: absolute;
    top: 42%;
    transform: translate(0%, -100%);
    left: 0;
  }
  #home #bannerCampaignMqnMain .contentDesciptionMqn h6 {
    font-size: 18px;
  }
  #home #bannerCampaignMqnMain .contetnInfoBannercampains {
    width: 100%;
    top: 100%;
    left: 0;
  }
  #home #bannerCampaignMqnMain .itemPoster img {
    display: none;
  }
  #home #bannerCampaignMqnMain .itemCampaignMain {
    margin-bottom: 30px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor h1 {
    font-size: 14px;
    margin-top: -5px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor img {
    margin: -5px 5px 0px 5px;
    width: 18px;
  }
  #home #bannerIndoorMainMqn .titleIndoor p {
    padding: 0px 24px;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor p {
    position: relative;
    top: -5px;
    font-size: 12px;
  }
  #home .backgroundMenuMobile {
    padding: 0px 24px;
  }
  #home #meuMainPC .logo img {
    margin-top: 0px;
    width: 55% !important;
  }
  #home #detailLocation .itemServieDetail img {
    margin-left: -20px;
    height: 40px;
  }
  #home #detailLocation .infoDetailMqn {
    position: unset;
    bottom: unset;
  }
  #home #detailLocation .imgSpecSrceen img,
  #home #detailLocation .imgSpecSrceen video {
    margin-bottom: 0px;
    width: 100%;
  }
  #home #detailLocation .infoMainContent h1 {
    font-size: 1.75rem;
    line-height: 40px;
  }
  #home #detailLocation .infoMainContent h1 {
    font-size: 20px;
    line-height: 40px;
    margin-bottom: 0px;
  }
  #home .customCarosel img {
    width: 100%;
    height: 100%;
  }
  #home .ant-carousel .slick-slide {
    height: 100%;
  }
  #home #logoPartner {
    margin-top: 50px;
  }
  #home #logoPartner .ItemInfoLogoPartnerMqn h2 {
    font-size: 2rem;
    line-height: 45px;
    width: auto;
  }
  #home #backgroundVideoMqnMain .videoMainDetail img {
    width: 100%;
  }
  #home #startCampsiteMqn {
    height: 100%;
    padding: 50px 16px;
    min-height: 56vh;
  }
  #home #startCampsiteMqn .infoStartCampsMqn h1 {
    font-size: 18px;
  }
  #home #startCampsiteMqn .infoStartCampsMqn h2 {
    font-size: 32px;
  }
  #home #bannerHoverChange {
    height: auto;
    margin-top: 0px;
  }
  #home #bannerHoverChange .itemBackgroundLaptop {
    margin-bottom: 100%;
  }
  #home #bannerHoverChange .titleFeatureIndex h1 {
    font-size: 32px;
    margin-bottom: 10px;
    line-height: 50px;
  }
  #home #bannerHoverChange .infoMainDetailMqn h1 {
    font-size: 22px;
  }
  #home #bannerHoverChange .itemNumberMain {
    display: block;
    margin: 0 auto;
  }
  #home #speccificationMqnMain {
    padding: 15px;
  }
  #home #speccificationMqnMain .table td,
  #home #speccificationMqnMain .table th {
    border-width: 0 0 1px;
    padding: 0px;
    vertical-align: top;
  }
  #home #speccificationMqnMain .tableMainWhiteSpec {
    padding: 0px;
  }
  #home #speccificationMqnMain .titleSpec h1 {
    font-size: 32px;
    line-height: 45px;
  }
  #home #bannerBLogMain {
    padding: 10px;
  }
  #home #bannerBLogMain .itemBlogMqn {
    margin-bottom: 10px;
    padding: 10px;
    margin-top: 20px;
  }
  #login {
    padding: 0px 14px;
  }
  #login .iconSocialFooter img {
    width: 45px;
    float: right;
  }
  #speccificationMqnMain {
    width: auto;
  }
  #speccificationMqnMain .table th {
    padding: 10px !important;
  }
  #speccificationMqnMain .tableMainWhiteSpec {
    box-shadow: none !important;
  }
  #speccificationMqnMain .table td {
    padding: 0px 1px;
  }
  #speccificationMqnMain .table {
    margin-top: -35px;
  }
  #speccificationMqnMain .table ul li {
    margin-top: 8px;
  }
}
@media (max-width: 320px) {
  #home #bannerHeaderHome .contentTextInfoHeader h1 {
    font-size: 30px;
  }
  #home #bannerHeaderHome .contactSale,
  #home #bannerHeaderHome .resAccountMqn {
    height: 50px;
    font-size: 12px;
  }
  #home #bannerServiePack .titleBanner h1 {
    font-size: 20px;
  }
  #home #bannerServiePack .itemServieMqn h1 {
    font-size: 14px;
  }
  #home #bannerServiePack .itemServieMqn {
    margin-bottom: 30px;
  }
  #home #bannerServiePack .itemServieMqn p {
    line-height: 20px;
    font-size: 14px;
  }
  #home #bannerServiePack .itemServieMqn img {
    width: auto;
  }
  #home #bannerCampaignMqnMain .contetnInfoBannercampains {
    left: 0px;
  }
  #home #bannerCampaignMqnMain .contentDesciptionMqn h1 {
    font-size: 16px;
    margin-bottom: 0px;
  }
  #home #bannerCampaignMqnMain .contentDesciptionMqn h2 {
    font-size: 16px;
  }
  #home #bannerCampaignMqnMain .itemPoster img {
    border: 5px solid #2c2d2d;
  }
  #home #bannerCampaignMqnMain .contetnInfoBannercampains p,
  #home #bannerCampaignMqnMain .contetnInfoBannercampains h4 {
    font-size: 14px;
  }
  #home #bannerCampaignMqnMain .footerContent img {
    width: 20px !important;
    height: 20px !important;
  }
  #home #bannerIndoorMainMqn .itemIconContentIndoor h1 {
    font-size: 14px;
  }
  #home #detailLocation .infoMainContent h1 {
    line-height: 24px;
    margin-bottom: 16px;
  }
  #home #detailLocation .ItemInfoLogoPartnerMqn h2 {
    line-height: normal;
  }
  #home #logoPartner {
    margin-bottom: 0px;
  }
  #home #logoPartner .ItemInfoLogoPartnerMqn h1 {
    width: 270px;
  }
  #home #logoPartner .ItemInfoLogoPartnerMqn h2 {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 50px;
  }
  #home #meuMainPC .logo img {
    width: 70% !important;
  }
  #bannerItemShowInfoMqn {
    padding: 0px;
  }
  #bannerItemShowInfoMqn .contentImgBannerShow,
  #bannerItemShowInfoMqn .titleFeatureIndex {
    padding: 0px 16px;
  }
  #bannerItemShowInfoMqn .contentImgBannerShow button {
    font-size: 12px;
  }
  #bannerHoverChange .itemBackgroundLaptop {
    margin-bottom: 180px;
  }
  #startCampsiteMqn {
    height: 100%;
  }
  #startCampsiteMqn .infoStartCampsMqn h1 {
    margin-top: -33px;
    line-height: 32px;
  }
  #startCampsiteMqn .infoStartCampsMqn h2 {
    font-size: 28px;
  }
  #bannerHeaderHome .contactSale,
  #bannerHeaderHome .resAccountMqn {
    margin-bottom: 10px;
    width: 100%;
  }
  #home #speccificationMqnMain .table {
    margin-top: 30px;
  }
  #home #speccificationMqnMain .table th {
    padding: 10px !important;
  }
  #home #speccificationMqnMain .table ul li {
    padding: 0px 1px;
  }
  #home #speccificationMqnMain .title.is-bold {
    font-family: inherit;
  }
  #home #speccificationMqnMain .tableMainWhiteSpec {
    margin-left: -14px;
  }
  #home #speccificationMqnMain .titleSpec h3 {
    margin-top: -30px;
  }
}
