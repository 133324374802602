#login {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: linear-gradient(45deg, #18d7aa 10%, #bddff7 96%);
}
#login .iconSocialFooter img {
  width: 100%;
}
#login .headerCopyRight a {
  font-style: italic;
  color: #41d7aa;
  font-weight: bold;
  font-size: 16px;
}
#login .content {
  max-width: 500px;
  padding: 20px;
  margin: 20px auto;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  margin-top: 22px;
}
#login .logo {
  margin: 10px 0px 30px;
  text-align: center;
}
#login .logo .img {
  display: block;
  cursor: pointer;
}
#login .logo .img img {
  max-height: 70px;
}
#login .logo .sitename {
  display: block;
  position: relative;
  top: 5px;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #174eff;
}
#login .btnLogin {
  width: 100%;
}
