/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-gradient {
  background-image: -webkit-gradient(linear, 37.219838% 34.532506%, 36.425669% 93.178216%, from(#29cdff), to(#0a60ff), color-stop(0.37, #148eff));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.background-hover {
  transition: all 0.3s ease-out;
}
.background-hover:hover {
  background-color: #f9f9fc;
}
/* .ant-btn-primary{
    background: @primary-color-gradient;
    border: none;
} */
* {
  font-family: "Roboto", sans-serif;
}
body {
  overflow-x: hidden;
}
.imgModalListMqn {
  width: calc(100% / 500 * 350) !important;
  padding: 0 !important;
  margin: 0 auto !important;
  display: table !important;
}
#admin {
  /* td {
    .ant-form-item {
      margin-bottom: 0px !important;
    }

    .ant-form-item-control {
      line-height: normal;
    }
  } */
}
@media (max-width: 991px) {
  #admin #admin {
    /* .ant-table-body {
      overflow-x: scroll;
    } */
  }
  #admin #admin .google-map-container {
    height: 80vh;
    padding: 0px;
  }
  #admin #admin .header {
    width: 100% !important;
  }
  #admin #admin .main-layout {
    margin-left: 0px;
  }
  #admin #admin .main-layout.collapsed {
    margin-left: 0px;
  }
  #admin #admin .main-layout .scheduleCard {
    width: 100%;
  }
  #admin #admin .backTop {
    right: 20px;
    bottom: 20px;
  }
  #admin #admin .search .wrapResult {
    margin: 8px;
  }
  #admin #admin .logo {
    background: transparent !important;
    margin: 16px;
    margin-top: 5px;
    height: 100%;
  }
  #admin #admin .logo ul {
    background: #174eff !important;
    height: 100%;
  }
  #admin #admin .menuHeader {
    display: none;
  }
  #admin #admin .backgroundMenuMobile {
    background: transparent !important;
    display: block;
  }
}
@media (max-width: 575px) {
  #admin input {
    font-size: 16px !important;
  }
  #admin #admin .header-search {
    display: none;
  }
}
#admin .ant-picker-input > input[disabled] {
  color: #174eff;
}
#admin .ant-input-number-disabled .ant-input-number-input {
  color: #174eff;
}
#admin .ant-select-disabled.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: #174eff;
}
#admin .ant-radio-disabled.ant-radio-checked .ant-radio-inner {
  border-color: #174eff !important;
}
#admin .ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
  background-color: #174eff;
}
#admin .ant-radio-disabled.ant-radio-checked + span {
  color: #174eff;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  border-color: #174eff !important;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: #174eff;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-checked + span {
  color: #174eff;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  border-color: #174eff !important;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  background-color: #174eff;
}
#admin .ant-tree-checkbox-disabled.ant-tree-checkbox-indeterminate + span {
  color: #174eff;
}
#admin .ant-slider-disabled .ant-slider-track {
  background-color: #174eff !important;
}
#admin .ant-slider-disabled .ant-slider-handle,
#admin .ant-slider-disabled .ant-slider-dot-active {
  border: solid 2px #174eff !important;
}
#admin .ant-slider-disabled .ant-slider-mark-text-active {
  color: #174eff;
}
#admin .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #fafafa !important;
}
#admin .audience-tab-main > .ant-collapse-item > .ant-collapse-item-active {
  height: 318px !important;
}
#admin .audience-tab-main img {
  height: 137px;
  width: 100%;
  object-fit: contain;
}
#admin .main-layout .googleMapMqnMain {
  position: sticky;
  top: 0;
}
#admin .ant-menu-horizontal > .ant-menu-item a {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}
#admin .ant-menu {
  background: #174eff !important;
}
#admin .backgroundMenuMobile {
  display: none;
}
#admin .content {
  min-height: 100vh !important;
  background: white;
}
#admin .mrt-5 {
  margin-top: 5px !important;
}
#admin .ant-menu-submenu {
  margin: 0 !important;
}
#admin .ant-menu-submenu-title {
  position: relative;
  text-align: center;
  padding: 0 25px;
}
#admin .ant-input-number {
  width: 100%;
}
#admin .opacity {
  opacity: 0.4;
}
#admin .table-preview-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}
#admin .mediaImgVideo video,
#admin .mediaImgVideo img {
  width: 100%;
  height: 100%;
}
#admin .mrt-Content {
  margin: 0px !important;
}
#admin .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
#admin .google-map-container {
  height: 100vh;
}
#admin .google-map-container-detail {
  height: 100%;
  min-height: 50vh;
}
#admin .trigger:hover {
  color: #1890ff;
}
#admin .logo {
  height: 32px;
  background: rgba(200, 200, 200, 0.2);
  margin: 16px;
}
#admin .ant-menu-item-group-title i {
  margin-right: 5px;
}
#admin .header {
  padding: 0;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  display: block;
  height: 72px;
  z-index: 9;
  background: #174eff !important;
}
#admin .header.fixed {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 256px);
  z-index: 29;
  transition: width 0.2s;
}
#admin .header .ant-menu-submenu-title {
  height: 72px;
  color: #fff;
}
#admin .header .ant-menu-submenu-active > .ant-menu-submenu-title {
  color: #174eff;
  background-color: #f9f9fc;
}
#admin .header .ant-menu-horizontal {
  line-height: 72px;
}
#admin .header .ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #174eff;
  background-color: #f9f9fc;
}
#admin .header .ant-menu-horizontal > .ant-menu-submenu > .noneHover:hover {
  color: #174eff;
  background-color: transparent !important;
}
#admin .header .ant-menu {
  border-bottom: none;
  height: 72px;
}
#admin .header .ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
}
#admin .header .ant-menu-horizontal > .ant-menu-item,
#admin .header .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none;
}
#admin .header .ant-menu-horizontal > .ant-menu-item-active,
#admin .header .ant-menu-horizontal > .ant-menu-item-open,
#admin .header .ant-menu-horizontal > .ant-menu-item-selected,
#admin .header .ant-menu-horizontal > .ant-menu-item:hover,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-active,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-open,
#admin .header .ant-menu-horizontal > .ant-menu-submenu-selected,
#admin .header .ant-menu-horizontal > .ant-menu-submenu:hover {
  border-bottom: none;
}
#admin .header .header-search {
  width: 300px;
  border-radius: 20px;
}
#admin .header .rightContainer {
  position: relative;
  float: right;
}
#admin .header .header-btn {
  color: #fff;
  margin-right: 8px;
  font-weight: bold;
}
#admin .backTop {
  right: 50px;
}
#admin .backTop .ant-back-top-content {
  background: #174eff;
  opacity: 0.3;
  transition: all 0.3s;
  box-shadow: 0 0 15px 1px rgba(69, 65, 78, 0.1);
}
#admin .backTop .ant-back-top-content:hover {
  opacity: 1;
}
#admin .main-layout {
  margin-left: calc(256px + 0px);
}
#admin .main-layout.collapsed {
  margin-left: 0px;
}
#admin .main-layout .breadcumb-right {
  float: right;
  background: rgba(255, 255, 255, 0.3);
  padding: 15px 20px;
  border-radius: 50px;
}
#admin .main-layout .breadcumbs {
  background: linear-gradient(90deg, #174eff, #18d7aa);
  padding: 16px 32px 100px;
}
#admin .main-layout .breadcumbs * {
  color: #fff !important;
}
#admin .main-layout .scheduleCard {
  max-width: 768px;
  margin: auto;
}
#admin .main-layout > .content {
  background: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}
#admin .main-layout > .content.transparent {
  background: transparent;
  padding: 5px 0px 0px;
  box-shadow: none;
}
#admin .main-layout .whiteBox {
  background: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
}
#admin .centerBtn {
  display: block;
  margin: auto;
  margin-bottom: 16px;
}
#admin .btn-top {
  margin-bottom: 16px;
  margin-right: 8px;
}
#admin .btn-margin-right {
  margin-right: 8px;
}
#admin .ant-form-item .ant-form-item {
  margin-bottom: 0px !important;
}
#admin .btn-sendMessage {
  cursor: pointer;
}
#admin .btn-sendMessage:hover img {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}
#admin .dashboard {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-40%, -40%);
  width: 100%;
}
#admin .dashboard h1 {
  font-weight: 400;
}
#admin .dashboard .ant-col {
  transition: 0.5s;
}
#admin .dashboard .ant-col i {
  font-size: 30px;
  color: #174eff;
}
#admin .dashboard .ant-col h4 {
  font-weight: 400;
}
#admin .dashboard .ant-col:hover {
  background-color: #d0fdd0;
}
#admin .order .wrapContainer {
  background: #fff;
  padding: 16px;
  margin: -80px 8px 8px;
  border-radius: 4px;
}
#admin .order .wrapOrderTemp {
  margin: -80px 32px;
}
#admin .search .wrapResult {
  margin: 8px 32px;
}
#admin .search .wrapResult .wrapContainer {
  background: #fff;
  border-radius: 4px;
  padding: 16px;
}
#admin .search .wrapResult .wrapContainer .avatar {
  margin-top: -120px;
  text-align: center;
}
#admin .search .wrapResult .wrapContainer .avatar .ant-avatar {
  border: 2px solid #fff;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
#admin .search .wrapResult .wrapContainer:hover .ant-avatar {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  transform: scale(1.5) translate(0px, -30px);
  margin-top: -10px;
}
#admin .search .wrapResult .wrapContainer .name {
  text-align: center;
}
#admin .schedule .addMore {
  margin: 0px;
  text-align: center;
  color: #174eff;
  height: 150px;
  padding: 35px 0px;
  cursor: pointer;
}
#admin .schedule .addMore .anticon {
  font-size: 40px;
  font-weight: bold;
}
#admin .orderTempItem {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
#admin .orderTempItem .ant-card-body {
  position: relative;
}
#admin .orderTempItem .background {
  background-image: url(/static/img/sidebar-bg.png);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: 90%;
  transform: scaleX(-1);
  background-position: -60px -100px;
  background-repeat: repeat-y;
  opacity: 0.6;
  cursor: pointer;
}
#admin .orderTempItem .head {
  margin-top: -90px;
}
#admin .orderTempItem .head .avatar {
  display: inline-block;
}
#admin .orderTempItem .head .avatar .ant-avatar {
  border: 4px solid #fff;
  background: linear-gradient(45deg, #6f91ff 0%, #6f91ff 60%);
}
#admin .orderTempItem .head .avatar .ant-avatar .ant-avatar-string {
  font-size: 40px;
}
#admin .orderTempItem .head .name {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
  padding-left: 10px;
  vertical-align: bottom;
}
#admin .orderTempItem .info {
  font-size: 16px;
  margin: 10px;
}
#admin .footer {
  text-align: right;
}
#admin .devicesGoogleMap .relativeMainMqn {
  height: 100%;
}
#admin .devicesGoogleMap .relativeMainMqn > .google-map-container {
  height: 100%;
}
/* .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent !important;
} */
.ant-layout-sider {
  position: fixed;
  height: 100vh;
  width: 256px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  background-image: url("/static/img/sidebar-bg.png") !important;
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: 60% 100% !important;
}
.ant-layout-sider .sidebar-btn {
  padding: 4px;
  border: 1px solid #004fff;
  margin: 8px;
  border-radius: 20px;
  text-align: center;
  color: #004fff;
}
.ant-layout-sider .sidebar-profile .profile-avatar {
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-avatar .avatar {
  border-radius: 50%;
  width: 80px;
  border: 4px solid #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  margin: 20px 0px;
}
.ant-layout-sider .sidebar-profile .profile-userInfo {
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-name {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-groupName {
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-companyName {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-address {
  text-align: center;
  margin: 0px 8px;
  font-weight: 300px;
}
.ant-layout-sider .sidebar-profile .profile-info {
  margin-top: 20px;
  text-align: center;
}
.ant-layout-sider .sidebar-profile .profile-info .value {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
}
.ant-layout-sider .sidebar-profile .profile-info .label {
  margin: 0px;
}
.ant-layout-sider .sidebar-profile .profile-expires {
  font-size: 11px;
  font-style: italic;
  margin-top: 12px;
  padding: 0px 8px;
}
.ant-layout-sider .sidebar-profile .profile-endLine {
  margin: 8px 0px;
}
.customer-search {
  top: 20px !important;
}
.customer-search .ant-modal-content {
  background-image: url("/static/img/cloud.jpg");
}
.customer-search .ant-modal-confirm-content {
  margin-left: inherit !important;
}
.order-assign-user .ant-modal-confirm-btns {
  display: none;
}
.order-assign-user .ant-modal-confirm-content {
  margin-left: 0 !important;
}
.order-assign-user .ant-modal-body {
  padding: 24px;
}
.ant-btn.clearBefore::before {
  top: -1px;
  left: -1px;
  right: unset;
  bottom: unset;
}
.order-tab-selected {
  background-color: #4caf50;
  /* Green */
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}
.order-tab-unselected {
  background-color: #dedede;
  border: none;
  color: rgba(0, 0, 0, 0.65);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
}
.temp-order-card {
  background: #00bf8f;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #001510, #00bf8f);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #001510, #00bf8f);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  height: 150;
}
.loadingCenter {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.ant-col {
  width: 100% !important;
  text-align: start !important;
}
.order-next-button {
  float: right !important;
}
.whiteBg {
  background: white;
}
.textHelper {
  color: #333;
  font-size: 13px;
  font-weight: 300;
  line-height: 35px;
}
.customer .cover {
  height: 150px;
  margin: -4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.customer .avatar {
  text-align: center;
  margin-bottom: 20px;
  margin-top: -80px;
}
.customer .avatar .ant-avatar {
  border: 3px solid #fff;
}
.customer .boxTitle {
  margin: 8px 20px;
  font-size: 20px;
  font-weight: bold;
  color: #174eff;
}
.ant-modal-confirm-content {
  margin-left: 0 !important;
}
.chartMainMqn {
  width: 40%;
  position: absolute;
  top: 10px;
  right: 10px;
}
.backgroundRetiv {
  position: relative;
  margin: auto;
  height: 100%;
}
.abChartMain {
  background: transparent;
}
.scrollUpButton img {
  position: fixed;
  bottom: 10px;
  right: 20px;
  width: 4%;
  cursor: pointer;
}
.creativeItem .thubnail {
  float: left;
  width: 70px;
  margin-right: 10px;
}
.creativeItem .description {
  color: #999;
  font-size: 0.75em;
}
.create-audiences .ant-col {
  width: unset !important;
}
.actionBar .ant-alert-message {
  color: white;
}
.actionBar .ant-alert-close-icon .anticon-close {
  color: white;
}
.detailTitle {
  border-bottom: thin solid #f1f1f1 !important;
}
.printDetailTooltip {
  max-width: 100% !important;
}
.sliderInChart .ant-slider-rail {
  height: 12px;
}
.sliderInChart .ant-slider-track {
  height: 12px;
  background-color: #18d7aa;
}
.sliderInChart .ant-slider-handle {
  display: none;
}
.ant-alert-success {
  background-color: #fff !important;
  border: 1px solid #b7eb8f;
}
.ant-tabs-tab-next,
.ant-tabs-tab-prev {
  display: none !important;
}
.ant-tabs-nav span.tabs-name {
  text-transform: uppercase;
  font-weight: bold;
}
.btn-right {
  float: right !important;
  z-index: 999;
  margin-left: 8px;
}
.turquoise {
  color: #18d7aa !important;
}
.textAlignEnd {
  text-align: end !important;
}
.card-layout-screen .ant-card-head-title .anticon {
  margin-right: 10px;
  font-size: 20px;
}
.container-description {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.container-description .text-bolder {
  font-weight: bolder;
}
.container-description .text-bolder:not(:last-child) {
  margin-right: 20px;
}
.container-description .rectangle {
  width: 40px;
  height: 20px;
  margin: 0 10px;
}
.container-description .rectangle-blue {
  background-color: #174eff;
  border: solid 1px #ffffff;
}
.container-description .rectangle-white {
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
}
.group-action-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.group-action-icons .anticon {
  font-size: 1.25em;
}
.group-action-icons .anticon:not(:last-child) {
  margin-right: 10px;
}
.group-action-icons .anticon-eye {
  color: #2f54eb;
}
.group-action-icons .anticon-edit {
  color: #0050b3;
}
.group-action-icons .anticon-dollar {
  color: #d48806;
}
.group-action-icons .anticon-setting {
  color: #1f1f1f;
}
.group-action-icons .anticon-fund-projection-screen {
  color: #389e0d;
}
.group-action-icons .anticon-calendar {
  color: #0050b3;
}
.group-action-icons .anticon-undo {
  color: #ad4e00;
}
.group-action-icons .anticon-copy {
  color: #2f54eb;
}
.group-action-icons .anticon-audit {
  color: #531dab;
}
.schedule {
  overflow-x: auto;
}
.schedule .schedule-container {
  min-width: 900px;
}
.schedule .schedule-container .ant-row {
  margin: 0 !important;
}
.schedule .schedule-container .ant-col {
  padding: 0px !important;
  min-height: 32px;
}
.schedule .schedule-container .ant-col .ant-input-number {
  border-radius: inherit !important;
  outline: none;
}
.schedule .schedule-container .ant-col .all-day {
  color: rgb(62 99 219);
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.schedule .schedule-container .ant-col .all-day-bottom {
  margin-top: 10px;
}
.schedule .schedule-container .ant-col .daily {
  color: rgb(62 99 219);
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}
.schedule .schedule-container .ant-col .daily-left {
  justify-content: flex-end;
  max-width: 70px !important;
}
.schedule .schedule-container .ant-col .daily-right {
  justify-content: flex-start;
  margin-left: 5px;
  font-size: 0.65rem;
}
.schedule .schedule-container .time-slot {
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
  opacity: 0.8;
}
.schedule .schedule-container .time-slot input.ant-input-number-input {
  color: #000;
  font-weight: 500;
  text-align: right;
}
.schedule .schedule-container .ant-input-number-handler-wrap {
  display: none;
}
.schedule .schedule-container .time-slot-disabled {
  background-color: #ffffff;
  border: "solid 1px #dcdcdc";
}
.schedule .schedule-container .time-slot-multiSelected {
  background-color: #969696;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.schedule .schedule-container .time-slot-multiSelected .time-slot-money {
  margin-right: 10px;
  font-weight: 500;
}
.schedule .schedule-container .time-slot-checkebox {
  background-color: #174eff;
  border: solid 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.schedule .schedule-container .time-slot-readonly {
  background-color: #ffffff;
  border: solid 1px #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.schedule .schedule-container .time-slot-readonly .time-slot-money {
  margin-right: 10px;
  font-weight: 500;
}
.schedule .schedule-container .time-slot-container-input {
  border: 0 !important;
}
.ant-timeline .ant-timeline-item-label {
  width: 14% !important;
}
.ant-timeline .ant-timeline-item-tail {
  border-color: #18d7aa !important;
  left: 18% !important;
}
.ant-timeline .ant-timeline-item-head {
  left: 18% !important;
}
.ant-timeline .ant-timeline-item-content {
  left: 18% !important;
  width: 80% !important;
}
.buyerListCampaigns .ant-tabs-tab {
  font-weight: bold;
}
.table-preview-image {
  max-width: 125px;
  max-height: 200px;
  cursor: pointer;
}
.modal-view-image {
  width: 100%;
}
.modal-view-video {
  width: 100%;
  max-height: 800px;
}
.text-note {
  font-weight: 500;
  font-style: italic;
}
.text-note .text-note-red {
  color: #f14a4a;
}
.notificationMain .unreadNotification {
  background-color: #e7efff;
}
.notificationMain .readNotification:hover {
  background-color: #e7efff;
}
@media (max-width: 1366px) {
  #admin .google-map-container-detail {
    height: 100%;
    min-height: 61vh;
  }
}
